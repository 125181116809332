@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Montserrat Underline';
}

body {
    margin: 0;
    font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.react-chatbot-kit-chat-container {
    position: relative;
    width: 100% !important;
}

.react-chatbot-kit-chat-bot-message {
    width: auto !important;
}
